@import "src/themes/mojito/styles/index.scss";
.Stepper {
  width: 100%;
  display: flex;
  justify-content: center;

  @include lg {
    position: relative;
    width: auto;
    top: 0;
  }

  &__steps {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;

    .step {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
    }

    &__header {
      background-color: getvar($colors, "neutral", "50");
      height: space(6.25);
      min-height: space(6.25);
      display: block;

      @include lg {
        display: none;
      }
    }

    &__divider {
      height: 0;
      border-top: 1px solid getvar($colors, "neutral", "400");
      padding: 0 space(1.5);
      margin: 0 space(1);
      border-top: 1px solid #a6a6a6;
      width: 3px;

      @include sm {
        width: space(12);
      }

      @include md {
        width: space(18);
      }
      @include lg {
        margin: 0 space(0.5);
        padding: 0;
        height: auto;
        border: none;
        width: auto;
      }
    }

    .button--step:focus {
      background-color: transparent;
    }
  }
}
