@import "src/themes/mojito/styles/index.scss";
.intro.pricing {
  @include lg {
    padding: 1.5rem 1rem 1.5rem 1rem !important;
    margin-bottom: 4rem !important;
  }
}

.intro-banner {
  display: flex;
  justify-content: center;
  padding-bottom: 1rem !important;

  @include lg {
    padding-bottom: 1rem !important;
  }

  &__wrapper {
    width: 100%;
    max-width: getvar($breakpoints, "lg");
  }

  &__title {
    margin-bottom: 0rem;
    @include lg {
      margin-bottom: 1rem;
    }
  }

  &__subtitle {
    padding: 0 3rem;
    @include sm {
      margin-bottom: 0;
      text-align: left;
      padding: 0 0 0 space(4);
      flex: 1;
    }

    @include lg {
      font-size: space(1.5);
      padding: 0;
      flex: 2;
      text-align: center;
    }

    &__wrapper {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      margin-top: 0;

      span {
        margin: 0.5rem 0 !important;
        max-width: none !important;

        @include lg {
          margin: 1rem 0 !important;
        }
      }
    }
  }

  &__button {
    width: 100%;
    background-color: white;
    position: fixed;
    bottom: 0;
    display: flex;
    justify-content: center;
    padding: 1.5rem 0;
    box-shadow: 0 0px 8px rgba(getvar($colors, "neutral", "700"), 0.24);
    z-index: 2;

    @include sm {
      background-color: transparent;
    }
    button {
      max-height: 56px;
      width: 80%;
      max-width: 306px;
      display: flex !important;
      background-color: #00af63;

      span {
        display: block !important;
        margin: 0 !important;
        font-weight: 800;
        font-size: 1.25rem;
      }

      &:hover {
        background-color: #006036;
      }

      @include sm {
        width: 208px;
      }

      @include lg {
        width: 282px;
        max-width: 282px;
      }
    }

    @include sm {
      position: relative;
      width: auto;
      padding: 0;
      box-shadow: none;
      flex: 1;
      justify-content: flex-end;
      padding-right: space(4);
    }

    @include lg {
      justify-content: center;
    }
  }

  &--v2 {
    .intro-banner__button {
      width: 100%;
      background-color: white;
      position: fixed;
      bottom: 0;
      display: flex;
      justify-content: center;
      padding: 1.5rem 0;
      box-shadow: 0 0px 8px rgba(getvar($colors, "neutral", "700"), 0.24);
      z-index: 2;
      @include sm {
        background-color: transparent;
        position: relative;
        width: auto;
        padding: 0;
        box-shadow: none;
        flex: 1;
        justify-content: flex-end;
      }
      @include lg {
        justify-content: center;
      }

      button {
        max-height: 56px;
        width: 100%;
        max-width: 343px;
        display: flex !important;
        background-color: #00af63;

        span {
          display: block !important;
          margin: 0 !important;
          font-weight: 800;
          font-size: 1.25rem;
        }

        &:hover {
          background-color: #006036;
        }

        @include sm {
          width: 100%;
        }

        @include lg {
          width: 282px;
          max-width: 282px;
        }
      }
    }

    .intro-banner__subtitle__wrapper {
      flex-direction: column;
      @include sm {
        width: 90%;
        margin: 0 auto;
        flex-direction: row;
      }
      @include lg {
        width: 1112px;
        margin: 0 auto;
      }

      p {
        font-size: space(1.25);

        font-weight: 700;
        @include sm {
          display: flex;
          flex: 2;
          text-align: left;
        }
        @include lg {
          font-size: space(1.5);
          justify-content: center;
          flex: 2;
        }
      }
    }
    .intro-banner__subtitle {
      text-align: center;
      font-weight: 400;
      font-size: space(0);
      padding: 0 !important;

      @include sm {
        text-align: left;
        font-size: space(1.125);
      }

      @include lg {
        text-align: right;
        font-weight: 700;
        font-size: space(1.5);
      }
    }
  }
}

.plan-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center !important;
  min-height: 271.6px;
  padding-bottom: 1rem;
  gap: space(0.875);
  justify-content: center;

  @include lg {
    padding-top: 16px;
    flex-direction: row;
    align-items: flex-start !important;
    margin-bottom: 0;
    padding-bottom: 0;
    gap: space(0);
  }

  .plan-box:first-child {
    margin-bottom: 20px;
    @include lg {
      margin-bottom: 0px;
    }
  }
}

.plan-box {
  width: 100%;
  max-width: 343px;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: box-shadow 0.2s linear;
  padding: space(2) !important;
  cursor: pointer;

  &:hover {
    box-shadow: 0 5px 8px 0 rgb(89 89 89 / 38%);
  }

  p,
  h1 {
    margin-bottom: 0 !important;
  }

  @include sm {
    width: 100%;
    max-width: 90%;
  }

  @include lg {
    height: 480px;
    min-height: 480px;
    max-width: 358px;
    flex-direction: column !important;
    justify-content: flex-start !important;
    align-items: flex-start !important;
    padding-bottom: 1.5rem !important;

    p.paragraph-heading {
      margin-bottom: space(1) !important;
      width: 100%;
    }
  }

  &--v2 {
    @include lg {
      height: 425px;
      min-height: 425px;
      flex-direction: column !important;
      justify-content: flex-start !important;
      align-items: flex-start !important;
      padding-bottom: 1.5rem !important;

      p.paragraph-heading {
        margin-bottom: space(1) !important;
        width: 100%;
      }
    }
    .plan-box__list .Chip {
      @include sm {
        margin-left: 8px;
      }
      @include lg {
        margin-left: 0;
      }
    }

    .plan-box__title {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      @include sm {
        flex-direction: row;
      }
      @include lg {
        flex-direction: column;
      }

      &__radio {
        margin-right: space(0.75);
      }
    }
    .plan-box__name .Chip {
      top: space(1.438);
      right: space(1);

      @include sm {
        position: relative;
        top: 0px;
        right: -14px;
      }
      @include lg {
        position: absolute;
        top: space(1.625);
        right: space(0.75);
      }
    }
    .plan-box__name {
      display: flex;
      justify-content: flex-start;
      width: 50%;
      align-items: center;
      column-gap: 0px;
      font-family: getvar($typography, "font-family-titles");

      @include sm {
        width: 100%;
      }

      @include lg {
        column-gap: 8px;
      }

      p {
        font-size: space(1);
        font-weight: 700;
        @include sm {
          font-size: space(1.5);
        }
      }
    }

    .plan-box__price {
      display: flex;
      flex-direction: row;
      align-self: baseline;
      padding-left: space(1.75);
      width: 90%;
      justify-content: space-between;

      @include sm {
        flex-direction: row-reverse;
        align-self: baseline;
        margin-left: 0px;
        justify-content: flex-start;
      }

      @include lg {
        flex-direction: column;
        align-self: baseline;
        gap: 4px;
      }

      p {
        @include sm {
          font-size: space(1.5);
        }
      }
    }
    .RadioButton-container {
      @include lg {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: space(0.25);
      }
    }
  }

  &--v2.plan-box--selected {
    .plan-box__list .Chip {
      @include lg {
        font-size: space(1);
      }
    }
  }

  &--selected {
    border: 2px solid getvar($colors, "primary", "500");
    border-top-width: 2px;
    padding: space(1.4) !important;

    @include lg {
      border-top-width: 10px;
    }

    &:hover {
      box-shadow: none !important;
    }

    .plan-box__header {
      background-color: getvar($colors, "primary", "500");
      border: 2px solid getvar($colors, "primary", "500");
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
      color: white;
      text-align: center;
      position: absolute;
      width: 100%;
      top: -22px;
      left: -2px;

      @include lg {
        top: -46px;
      }

      h4 {
        margin-bottom: 0 !important;
        font-size: 14px;

        @include lg {
          padding: 11px 0;
          font-size: 18px;
        }
      }
    }
  }

  &__header {
    background-color: getvar($colors, "primary", "200");
    border: 2px solid getvar($colors, "primary", "200");
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    color: white;
    text-align: center;
    position: absolute;
    width: 100%;
    top: -22px;
    left: -2px;

    @include lg {
      top: -46px;
    }

    h4 {
      margin-bottom: 0 !important;
      font-size: 14px;

      @include lg {
        padding: 11px 0;
        font-size: 18px;
      }
    }
  }

  &__ul {
    display: none;
    padding: 18px;

    @include sm {
      width: 100%;
      display: flex;
      margin-top: space(1);
      flex-direction: column;
      max-height: 175px;
      flex-wrap: wrap;
      padding: 0;
    }

    @include lg {
      display: block;
      margin-top: 1rem;
      max-height: unset;
    }

    &.first-plan-benefits {
      @include sm {
        max-height: 85px;
      }
      @include lg {
        max-height: unset;
      }
    }
  }

  &__ul-v2 {
    display: none;
    padding: 18px;

    @include sm {
      width: 100%;
      display: flex;
      margin-top: space(1);
      flex-direction: column;
      max-height: 165px;
      flex-wrap: wrap;
      padding: 0;
    }

    @include lg {
      display: block;
      margin-top: 1rem;
      max-height: unset;
    }

    &.first-plan-benefits-v2 {
      @include sm {
        max-height: 165px;
      }
      @include lg {
        max-height: unset;
      }
    }
  }

  &__ul-v3 {
    display: none;
    padding: 18px;

    @include sm {
      width: 100%;
      display: flex;
      margin-top: space(1);
      flex-direction: column;
      max-height: 190px;
      flex-wrap: wrap;
      padding: 0;
    }

    @include lg {
      display: block;
      margin-top: 1rem;
      max-height: unset;
    }
  }

  &__ul-v3.first-plan-benefits {
    @include sm {
      max-height: 170px;
    }
  }

  &__list {
    gap: space(1) !important;

    @include sm {
      gap: 0.5rem !important;

      span:last-of-type {
        width: 244px;
        position: relative;
      }

      .Chip {
        position: absolute;
        left: 267px;
        span {
          width: auto;
        }
      }
    }
    @include lg {
      gap: space(0.5) !important;
      span:last-of-type {
        width: unset;
        position: relative;
      }
      .Chip {
        position: initial;
      }
    }
  }

  &__title {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: 16px;
    gap: 8px;
    justify-content: space-between;
    @include sm {
      flex-direction: row;
    }

    @include lg {
      flex-direction: column;
      font-size: space(1.5);
      line-height: space(1.5);
      height: unset;
    }

    .price-subcontent {
      font-weight: 400;
      font-size: space(0.75);
      line-height: space(1.125);

      &-left {
        font-style: italic;
        display: flex;
        align-items: flex-start;
        margin-left: space(2.5);
        margin-top: space(0.375);
        color: getvar($colors, "neutral", "600");
        @include sm {
          margin-top: space(0.375);
        }
        @include lg {
          margin-top: -4px;
          margin-left: space(0.688);
        }
      }
    }

    &__radio {
      height: 1.1rem;
      width: 1.1rem;
      margin: 0;
      margin-right: space(1);
      accent-color: getvar($colors, "primary", "500");
    }
  }

  &__name {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    align-items: center;
    column-gap: 8px;
  }

  .paragraph-heading {
    font-size: space(1.5);
    line-height: space(2);
    font-weight: 700;
    font-family: getvar($typography, "font-family-titles");

    @include lg {
      font-size: space(2.25);
      line-height: space(3);
    }
  }

  .plan-price-container {
    margin-right: 4px;

    @include lg {
      position: relative;
      text-align: center;
      margin-right: 0;
    }
  }
  .plan-price-container-left {
    @include sm {
      margin-left: space(0.75);
      font-size: space(1.125);
    }

    @include lg {
      margin-left: space(0.688);
    }
  }
}

.plan-box:not(:last-child) {
  @include lg {
    margin-right: space(1.5);
  }
}

.dynamic-benefits {
  padding: space(1) space(1.5);
  background-color: white;
  display: block;

  @include sm {
    display: none !important;
    padding: space(1.5);
  }

  &__wrapper {
    max-width: 343px;
    margin: 0 auto;

    .plan-box__ul {
      display: block;
      margin-bottom: 0;

      @include sm {
        display: none !important;
      }
    }
  }
}

.phone-box {
  &__wrapper {
    background-color: getvar($colors, "primary", "50");
    display: flex;
    justify-content: center;
    margin-bottom: 24px;

    div {
      max-width: 21rem;
      margin: 1rem;

      @include lg {
        max-width: 22rem;
        margin: 0;
        margin-top: 1.5rem;
      }
    }
  }
  &--v2 {
    margin: space(3) space(0);
    div.u-text-center {
      margin-top: 0 !important;
    }
  }
}

.Footer {
  margin-bottom: 80px;

  @include sm {
    margin-bottom: space(0.5);
  }
  @include lg {
    margin-bottom: inherit;
  }
}
