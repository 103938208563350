@import "src/themes/mojito/styles/index.scss";
.Loading {
  display: inline-flex;

  &--complete {
    position: absolute;
    z-index: 99999;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(38, 38, 38, 0.75);
    flex-direction: column;
  }
}

#loadingSpinner {
  animation: spin 2s linear infinite;
  display: inline-flex;

  &--complete {
    animation: spin 2s linear infinite;
    font-size: 7rem;
    color: white;
  }

  @-moz-keyframes spin {
    100% {
      -moz-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
  @keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
}

.loader2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: inline-block;
  border-top: 5px solid #fff;
  border-right: 5px solid transparent;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
