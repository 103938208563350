@import "src/themes/mojito/styles/index.scss";
.wrapper {
  max-width: getvar($breakpoints, "lg");
  margin-left: auto;
  margin-right: auto;
  padding-left: space();
  padding-right: space();

  @include sm {
    padding-left: space(0);
    padding-right: space(0);
  }

  @include md {
    padding-left: space(1.5);
    padding-right: space(1.5);
  }

  &--staticPage {
    max-width: getvar($breakpoints, "staticPage");
    margin-bottom: 40px;
  }

  &--staticPage.wrapper--sm {
    max-width: getvar($breakpoints, "staticPage--sm");
  }

  &--xl {
    max-width: getvar($breakpoints, "xl");
  }

  &--lg {
    max-width: getvar($breakpoints, "lg");
  }

  &--md {
    max-width: getvar($breakpoints, "md");
  }

  &--sm {
    max-width: getvar($breakpoints, "sm");
  }

  &--xs {
    max-width: getvar($breakpoints, "xs");
  }
}
