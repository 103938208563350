@import "src/themes/mojito/styles/index.scss";
.button--deactivated.button--disabled {
  color: getvar($colors, "neutral", "300");
}

.button-toggle {
  border: none !important;
  border-radius: 100px !important;

  padding: 4px 24px !important;

  .button__text {
    font-weight: 700 !important;
    font-size: 14px !important;
  }
}

.button-toggle.button--secondary {
  background-color: getvar($colors, "primary", "500");
  color: getvar($colors, "neutral", "50");
}

.button-toggle.button--deactivated.button--secondary {
  background-color: transparent;
  color: getvar($colors, "neutral", "300") !important;
}
